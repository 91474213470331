$(document).ready(function() {
    $(document).on('submit', '[data-ajax="form"]', function (e) {
        e.preventDefault();
        var form = $(this);
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            // dataType: "json",
            success: function (data, status, xhr) {
                console.log(data);
                var ct = xhr.getResponseHeader("content-type") || "";
                if (ct.indexOf('json') > -1) {
                    // are we successful?
                    if(data.type == 'success') {
                        form.find('.form-group').removeClass('has-error').addClass('has-success');
                        form.find('.help-block').remove();
                        //if form is inside a modal
                        var modal = form.parents('.modal');
                        if (modal.length > 0) {
                            if(data.type == 'success'){
                                modal.addClass('success');
                                setTimeout(function(){
                                    modal.modal('toggle');
                                    modal.removeClass('success')
                                }, 1500);
                            } else {
                            }
                            modal.find('.modal-content').addClass('success');
                        } else {
                            // no modal
                        }
                        var message = $('<div class="alert alert-success"></div>');
                        message.html(data.message);
                        form.find('.response').html(message);
                        if (data.redirect) {
                            setTimeout(function(){
                                window.location.href = data.redirect;
                            }, 1500);
                        }
                        form[0].reset();
                    } else {
                    	var message = $('<div class="alert alert-danger"></div>');
                        message.html(data.message);
                        form.find('.response').html(message);
                    } 
                } else {
                	var pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im
					var array_matches = pattern.exec(data);
                    var newForm = $(array_matches[1]).find('form');
                    form.html(newForm.html());                    	
                }
            },
            error: function (data, status, object) {
            }
        });
    });

});
$(document).ready(function () {
    console.log(13);
    setTimeout(function () {
        $(".error-remove").removeClass("error");
    }, 5000);

    datePicker();

    $('[data-toggle="class"]').click(function() {
        $($(this).attr("data-target")).toggleClass("show");
    })
})

function datePicker() {
    $('[data-datepicker]').each(function (index, element) {
        $(this).datetimepicker({
            locale: LOCALE
        });
    });
}
